
  var editorScriptEntry = require('/home/builduser/agent00/work/4cd68e1cc1f2e10b/albums/albums-ooi/src/editor.app.ts');

  

  
  var editorReadyWrapper = require('yoshi-flow-editor-runtime/build/esm/editorScript.js').editorReadyWrapper;
  var onEventWrapper = require('yoshi-flow-editor-runtime/build/esm/editorScript.js').onEventWrapper;
  var editorReady = editorScriptEntry.editorReady;
  var onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });

  var sentryConfig = {
    DSN: 'https://e72e679ae3614e728ffef05a9d27e663@sentry.wixpress.com/384',
    id: 'e72e679ae3614e728ffef05a9d27e663',
    projectName: 'albums-ooi',
    teamName: 'exposure',
  };

  var experimentsConfig = {"scope":"albums"};

  var translationsConfig = {"default":"en","defaultTranslationsPath":"/home/builduser/agent00/work/4cd68e1cc1f2e10b/albums/albums-ooi/src/assets/locales/messages_en.json"};

  var defaultTranslations = {"Dashboard_Main_Album_Details_NumPhotos":"photos","App_Manager_Create_Album_CTA":"Create Album","App_Manager_Manage_Albums_CTA":"Manage Albums","App_Manager_Manage_Albums_Store_CTA":"Manage Albums Store","App_Manager_Customize_Albums_Page_CTA":"Customize Albums Page","App_Manager_Upgrade_Banner_Text":"To sell photos in your albums, ","App_Manager_Upgrade_Banner_Link_Text":"upgrade now.","SETTINGS_PANEL_TITLE":"Photo Albums","GFPP_SETTINGS_LABEL":"Settings","GFPP_MANAGE_LABEL":"Manage Albums","adi.albums-page.settings.description":"Customize the display settings for your Photo Albums. To create new albums, or edit existing ones, click manage albums. ","adi.albums-page.settings.options":"When clicking on an album:","adi.albums-page.settings.dropdown1":"Album opens in a new tab","adi.albums-page.settings.dropdown2":"Albums opens in a popup","adi.albums-page.settings.dropdown3":"Manually","adi.albums-page.settings.manually.cta":"Reorder","adi.albums-page.settings.sort":"Sort Albums by: ","adi.albums-page.settings.sort.option1":"Newest first","adi.albums-page.settings.sort.option2":"Oldest first","adi.albums-page.settings.display":"Choose which Albums to display","adi.albums-page.settings.display.CTA":"Edit Album Visibility","adi.albums-page.settings.order.header":"Albums Order","adi.albums-page.settings.order.description":"Drag & drop your albums in the order that you want them to appear. ","adi.albums-page.settings.order.title.albums-list":"Album Order Display","adi.albums-page.settings.album-visibility-header":"Edit Album Visibility","adi.albums-page.settings.album-visibility-description":"Choose which albums will be displayed on your site. ","adi.albums-page.settings.album-visibility-title":"Album Visibility","adi.albums-page.settings.main-CTA":"Manage Albums"};

  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady: editorReady,
      sentryConfig: sentryConfig,
      experimentsConfig: experimentsConfig,
      translationsConfig: translationsConfig,
      defaultTranslations: defaultTranslations,
      artifactId: 'albums-ooi',
      optionalDeps: {
        
      },
    });
  }

  module.exports = editorScriptEntry.default || {
    ...editorScriptEntry,
    onEvent,
    editorReady,
  };
  
